$large-desktop-width: 1440px;
$medium-desktop-width: 1350px;
$desktop-width: 1280px;
$laptop-width: 1024px;
$tablet-width: 991px;
$mobile-width: 768px;
$medium-mobile-width: 600px;
$small-mobile-width: 500px;
$super-small-mobile-width: 430px;
$container-width: 1380px;

$body-background-color: #f4f2ed;
$body-background-color-darker: #e5e3de;
$body-background-color-darkest: #d6d3cd;
$main-color: #c58e86;
$second-color: #6c8f7e;

$header-text: #6c4a45;

$heading-color: #555555;
$text-color: #717070;
$text-gray: #939598;

@mixin bg {
  background: rgb(0, 174, 239);
  background: linear-gradient(
    145deg,
    rgba(0, 174, 239, 1) 0%,
    rgba(0, 166, 101, 1) 100%
  );
}

@mixin font-main {
  font-family: "Aestetico", sans-serif;
}

@mixin font-informal {
  font-family: "Aestetico Informal", sans-serif;
}

@mixin font-noto {
  font-family: "Noto Sans", sans-serif;
}

@mixin font-arial {
  font-family: Arial, Helvetica, sans-serif;
}

@mixin placeholder($value) {
  &::-webkit-input-placeholder {
    color: $value;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $value;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $value;
  }

  &:-ms-input-placeholder {
    color: $value;
  }
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

html,
body {
  margin: 0;
  font-size: 10px;
  background: $body-background-color;

  &.popup,
  &.menu-open {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.mb-20 {
  margin: 0 0 20px;
}

.btn {
  display: inline-block;
  font-size: 2.4rem;
  text-decoration: none;
  color: white;
  margin: 0;
  padding: 12px 30px;
  border: 2px solid #d6e8fb;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  @media all and (max-width: $desktop-width) {
    font-size: 2.4rem * 0.8;
    padding: 12px * 0.8 30px * 0.8;
  }

  @media all and (max-width: $tablet-width) {
    font-size: 2.4rem * 0.7;
    padding: 12px * 0.7 30px * 0.7;
  }

  @media all and (max-width: $medium-mobile-width) {
    font-size: 2.4rem * 0.6;
    padding: 12px * 0.6 30px * 0.6;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.btn-peach {
  display: inline-block;
  font-size: 2.6rem;
  text-decoration: none;
  color: white;
  margin: 0;
  padding: 12px 30px;
  border: 2px solid #d9a79f;
  background-color: rgba(197, 142, 134, 0.7);
  transition: all 0.3s ease;

  @media all and (max-width: $desktop-width) {
    font-size: 2.6rem * 0.8;
    padding: 12px * 0.8 30px * 0.8;
  }

  @media all and (max-width: $tablet-width) {
    font-size: 2.6rem * 0.7;
    padding: 12px * 0.7 30px * 0.7;
  }

  @media all and (max-width: $medium-mobile-width) {
    font-size: 2.6rem * 0.6;
    padding: 12px * 0.6 30px * 0.6;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.peach {
  color: $main-color !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
form,
input,
select,
li,
button,
textarea,
span {
  @include font-main();
  color: $text-color;
  outline: none;
}

h1 {
  display: none;
}

h2 {
  font-size: 5rem;
  line-height: 1.1em;
  font-weight: normal;
  margin: 0;
  letter-spacing: -1px;
  color: $heading-color;
  margin: 0 0 30px;

  @media all and (max-width: $desktop-width) {
    font-size: 5rem * 0.8;
  }

  @media all and (max-width: $tablet-width) {
    font-size: 5rem * 0.7;
  }

  @media all and (max-width: $medium-mobile-width) {
    font-size: 5rem * 0.6;
  }
}

h3 {
  font-size: 4rem;
  font-weight: normal;
  margin: 0 0 1em;
  line-height: 1em;
  color: $main-color;

  @media all and (max-width: $desktop-width) {
    font-size: 4rem * 0.8;
  }

  @media all and (max-width: $tablet-width) {
    font-size: 4rem * 0.7;
  }

  @media all and (max-width: $medium-mobile-width) {
    font-size: 4rem * 0.6;
  }
}

h4 {
  font-size: 3rem;
  font-weight: normal;
  line-height: 1.2em;
  margin: 0 0 0.8em;
  color: $main-color;

  @media all and (max-width: $desktop-width) {
    font-size: 3rem * 0.8;
  }

  @media all and (max-width: $tablet-width) {
    font-size: 3rem * 0.7;
  }

  @media all and (max-width: $medium-mobile-width) {
    font-size: 3rem * 0.6;
  }
}

h5 {
  font-size: 4rem;
  font-weight: normal;
  line-height: 1.2em;
  color: white;
  margin: 0 0 0.5em;

  @media all and (max-width: $desktop-width) {
    font-size: 4rem * 0.8;
  }

  @media all and (max-width: $tablet-width) {
    font-size: 4rem * 0.7;
  }

  @media all and (max-width: $medium-mobile-width) {
    font-size: 4rem * 0.6;
  }
}

h6 {
  @include font-noto();
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  margin: 0 0 0.5em;
  text-transform: uppercase;

  @media all and (max-width: $mobile-width) {
    font-size: 1.5rem * 0.9 !important;
  }
}

p,
li {
  line-height: 1.4em;
  color: $text-color;
  font-size: 2rem;

  @media all and (max-width: $desktop-width) {
    font-size: 2rem * 0.8;
  }

  @media all and (max-width: $tablet-width) {
    font-size: 2rem * 0.7;
  }

  @media all and (max-width: $medium-mobile-width) {
    font-size: 2rem * 0.6;
  }

  &.smaller {
    font-size: 1.5rem;
    @media all and (max-width: $tablet-width) {
      font-size: 1.5rem * 0.8;
    }

    @media all and (max-width: $medium-mobile-width) {
      font-size: 1.5rem * 0.7;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

strong {
  font-weight: bolder;
}

.container {
  width: $container-width + 40;
  max-width: 100%;
  margin: auto;
  padding: 0 20px;
}

.hide-mdesktop {
  @media all and (max-width: $medium-desktop-width) {
    display: none;
  }
}

.show-mdesktop {
  display: none;
  @media all and (max-width: $medium-desktop-width) {
    display: block;
  }
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}
