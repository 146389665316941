@include keyframes("pulse") {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 12px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@include keyframes("arrow") {
  0% {
    transform: translate3d(-50%, 0, 0);
  }
  60% {
    transform: translate3d(-50%, 10px, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

@include keyframes("pulse-mobile") {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

img {
  max-width: 100%;
  width: auto;
  height: auto;

  &.full-image {
    width: 100%;
    display: block;
  }
}

.arrow {
  @include animation("arrow 2s infinite");
}

.animate {
  &.animate-up {
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateY(50px);
    opacity: 0;

    &.ready {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &.animate-down {
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateY(-50px);
    opacity: 0;

    &.ready {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &.animate-left {
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateX(50px);
    opacity: 0;

    &.ready {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.animate-right {
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateX(-50px);
    opacity: 0;

    &.ready {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @for $i from 0 through 20 {
    &.animate-delay-#{$i} {
      transition-delay: 0.2s * $i;
    }
  }
}

#register,
#register-plan {
  display: none;
  position: fixed;
  overflow: auto;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -21px;
    right: 30px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: $main-color;
    @include font-arial();
    color: white;
    font-size: 3rem;
    line-height: 1em;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover,
    &:focus,
    &:active {
      background: lighten($main-color, 10%);
    }
  }

  form {
    padding: 100px 0;
    text-align: center;
    width: 1080px;
    max-width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: rgba(255, 255, 255, 0.86);

    @media all and (max-width: $desktop-width) {
      padding: 100px * 0.8 0;
    }

    @media all and (max-width: $tablet-width) {
      padding: 100px * 0.7 0;
    }

    @media all and (max-width: $medium-mobile-width) {
      padding: 100px * 0.6 0;
    }

    h3 {
      @include font-informal();
      font-size: 5rem;
      font-weight: bold;
      color: $text-gray;
      margin: 0 0 30px;

      @media all and (max-width: $desktop-width) {
        font-size: 5rem * 0.8;
      }

      @media all and (max-width: $tablet-width) {
        font-size: 5rem * 0.7;
      }

      @media all and (max-width: $medium-mobile-width) {
        font-size: 5rem * 0.6;
      }
    }

    .input-container {
      width: 715px;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: auto;
    }

    .input-group {
      width: 50%;
      padding: 0 15px 35px;

      @media all and (max-width: $mobile-width) {
        width: 100%;
      }

      input {
        font-size: 1.5rem;
        width: 100%;
        background: white;
        border: 0;
        height: 45px;
        padding: 0 15px;
        color: darken($text-gray, 10%);
        @include placeholder(#cacbcd);

        &.password,
        &.postcode {
          display: none;
        }
      }
    }

    .submit-group {
      padding: 0 15px;
      input {
        width: 305px;
        max-width: 100%;
        margin: auto;
        @include font-informal();
        color: white;
        background: $main-color;
        height: 45px;
        border: 0;
        font-size: 2rem;
        font-weight: bold;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover,
        &:focus,
        &:active {
          background: lighten($main-color, 10%);
        }
      }
    }
  }
}

#register-plan {
  .container {
    position: absolute;
    padding: 90px 20px;
    top: 50%;
    max-height: 90vh;
    transform: translateY(-50%);
    width: 100%;
    max-width: none;
    background: rgba(224, 222, 218, 0.9);

    @media all and (max-width: $desktop-width) {
      padding: 90px * 0.8 20px * 0.8;
    }

    @media all and (max-width: $tablet-width) {
      padding: 90px * 0.7 20px * 0.7;
    }

    @media all and (max-width: $medium-mobile-width) {
      padding: 90px * 0.6 20px * 0.6;
    }
  }

  .close {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  form {
    background: none;
    position: static;
    padding: 0 0 40px;
    transform: translate3d(0, 0, 0);
    width: auto;
    max-width: none;

    h3 {
      color: $main-color;
    }

    p {
      font-size: 1.5rem;
      color: #81807c;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 1.4em;
      margin: 0 0 5px;

      &.form-info {
        display: none;
        margin: 0;
      }
    }

    .input-group,
    .select-group,
    .submit-group {
      width: 415px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 0 0 10px;

      input {
        height: 41px;
      }
    }

    .select-group {
      position: relative;

      &:after {
        display: block;
        content: ">";
        position: absolute;
        top: 11px;
        right: 15px;
        font-size: 1.5rem;
        transform: rotate(90deg);
      }
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 100%;
        background: white;
        border: none;
        height: 41px;
        color: #cacbcd;
        padding: 0 14px;

        &.has-value {
          color: darken($text-gray, 10%);
        }
      }
    }

    .submit-group {
      input {
        width: 100%;
      }
    }

    .input-group-submit {
      position: relative;
      padding-right: 80px;

      input[type="submit"] {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        background: $main-color;
        @include font-informal();
        font-weight: bold;
        font-size: 1.6rem;
        color: white;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover,
        &:active,
        &:focus {
          background: lighten($main-color, 10%);
        }
      }
    }
  }
}

header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 35px 20px;
  background: url(/assets/img/bg-hero.jpg) center center repeat;
  width: $container-width;
  max-width: 100%;
  transition: 0.3s ease;

  @media all and (max-width: $desktop-width) {
    padding: 35px * 0.8 20px;
  }

  @media all and (max-width: $laptop-width) {
    padding: 15px 20px;
    height: 60px;
    width: 100%;
    left: 0;
    transform: translateX(0);
  }

  &.menu-open {
    transform: translateX(-240px);
  }

  &.scrolled {
    @media all and (min-width: 1025px) {
      position: fixed;
      transform: translate3d(-50%, -107px, 0);
      @media all and (max-width: $desktop-width) {
        transform: translate3d(-50%, -79px, 0);
      }

      &.up,
      &:hover {
        transform: translate3d(-50%, 0, 0);
      }
    }
  }

  nav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    @media all and (max-width: $laptop-width) {
      display: none;
    }

    &.mobile {
      display: none;

      @media all and (max-width: $laptop-width) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .logo {
        padding: 0;
      }
    }

    a,
    div {
      @include font-main();
      font-size: 1.5rem;
      line-height: 1em;
      white-space: nowrap;
      text-decoration: none;
      color: $header-text;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 10px 30px;
      transition: 0.3s ease;
      cursor: pointer;

      @media all and (max-width: $desktop-width) {
        padding: 10px 30px * 0.6;
      }

      &.logo {
        padding: 0 45px;

        @media all and (max-width: $desktop-width) {
          padding: 0 45px * 0.7;

          img {
            width: 203px * 0.7;
            height: 47px * 0.7;
          }
        }

        @media all and (max-width: $laptop-width) {
          img {
            width: 203px * 0.6;
            height: 46px * 0.6;
          }
        }
      }

      &.active,
      &.white,
      &:hover,
      &:active,
      &:focus {
        color: white;
      }

      &.white {
        &:focus,
        &:hover,
        &:active {
          color: $header-text;
        }
      }

      img {
        max-width: none;
      }

      &.btn-register {
        padding: 0px 15px;
      }

      span {
        color: inherit;
        display: inline-block;
        padding: 9px 14px 8px;
        border: 1px solid #d9a79f;
      }
    }

    div {
      position: relative;
      div.dropdown {
        display: none;
        position: absolute;
        left: 0;
        padding-top: 5px;
        padding-left: 0;
        padding-right: 0;
        a {
          display: block;
          padding-top: 3px;
          padding-bottom: 3px;
        }
      }

      &:hover,
      &:active {
        div.dropdown {
          display: block;
        }
      }
    }
  }
}

#mobile-menu {
  // display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  width: 240px;
  height: 100vh;
  background: white;
  border-left: 1px solid $main-color;
  transition: 0.3s ease;
  transform: translateX(240px);

  &.active {
    @media all and (max-width: $laptop-width) {
      transform: translateX(0);
    }
  }
  a {
    display: block;
    font-size: 1.6rem;
    color: $main-color;
    border-bottom: 1px solid $main-color;
    padding: 19px 20px;
    text-decoration: none;
    transition: color 0.3s ease;
    cursor: pointer;

    span {
      color: $main-color;
    }

    &.active,
    &:hover,
    &:active,
    &:focus {
      background: url(/assets/img/bg-hero.jpg) center center repeat;
      color: white;

      span {
        color: white;
      }
    }
  }
}

.main {
  transition: 0.3s ease;

  &.menu-open {
    transform: translateX(-240px);
  }
}

footer {
  background: $main-color;

  .footer-container {
    max-width: 1720px;
    padding: 65px 20px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: $medium-desktop-width) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .left-container {
      display: flex;
      align-items: flex-start;

      @media all and (max-width: $tablet-width) {
        flex-wrap: wrap;
        justify-content: center;

        .footer-logo {
          width: 100%;
          padding-right: 0 !important;
          margin-bottom: 50px !important;
          text-align: center;

          img {
            width: 137px * 0.8;
            height: 141px * 0.8;
          }
        }

        @media all and (max-width: $mobile-width) {
          display: block;
          width: 100%;
        }
      }

      .footer-content {
        padding-right: 70px;

        &:last-child {
          padding-right: 0;
        }

        @media all and (max-width: $mobile-width) {
          width: 250px;
          margin: auto;
          padding-right: 0;
        }
      }

      p {
        @include font-noto();
        font-size: 1.5rem !important;
        color: #e9cdc9;
        margin: 0 0 1em;

        @media all and (max-width: $mobile-width) {
          font-size: 1.5rem * 0.9 !important;
        }

        a {
          @include font-noto();
          color: inherit;
          text-decoration: none;
          transition: 0.3s ease;

          &:hover,
          &:focus,
          &:active {
            color: white;
          }
        }

        &.socials {
          a {
            opacity: 0.25;
            margin-right: 3px;

            &:hover,
            &:focus,
            &:active {
              opacity: 1;
            }
          }
        }
      }
    }

    .right-container {
      a {
        padding-left: 30px;
      }

      .forte {
        width: 173px;
        height: 63px;

        @media all and (max-width: $tablet-width) {
          width: 173px * 0.8;
          height: 63px * 0.8;
        }
      }

      .hachem {
        width: 95px;
        height: 86px;

        @media all and (max-width: $tablet-width) {
          width: 95px * 0.8;
          height: 86px * 0.8;
        }
      }

      .cornetta {
        width: 104px;
        height: 71px;

        @media all and (max-width: $tablet-width) {
          width: 104px * 0.8;
          height: 71px * 0.8;
        }
      }

      @media all and (max-width: $medium-desktop-width) {
        margin-top: 50px;
        a {
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }

  .footer-privacy-policy {
    background: #f2eee9;

    .footer-privacy-policy-container {
      max-width: 1720px;
      padding: 15px 20px;
      width: 100%;
      margin: auto;
    }

    p {
      @include font-arial();
      color: #858585;
      margin: 0;
      font-size: 1.2rem;

      a {
        transition: all 0.3s ease;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
          color: black;
        }
      }
    }
  }
}

#hero {
  position: relative;
  height: 100vh;
  background-size: cover !important;

  @media all and (max-width: 1920px) {
    max-height: 1081px;
  }

  @media all and (max-width: $tablet-width) {
    max-height: 800px;
    min-height: 600px;
  }

  @media all and (max-width: $small-mobile-width) {
    max-height: 600px;
    min-height: 400px;
  }

  #hero-ready {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-size: cover !important;

    &.ready {
      opacity: 1;
    }
  }

  img {
    position: relative;

    &.logo {
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translateX(-50%);
      transition: 0.3s ease;

      @media all and (max-width: $desktop-width) {
        width: 357px * 0.8;
        height: 367px * 0.8;
      }

      @media all and (max-width: $tablet-width) {
        width: 357px * 0.7;
        height: 367px * 0.7;
      }

      @media all and (max-width: $medium-mobile-width) {
        width: 357px * 0.6;
        height: 367px * 0.6;
      }
    }

    &.arrow {
      position: absolute;
      left: 50%;
      bottom: 60px;
      transform: translateX(-50%);
    }
  }
}

#statement {
  padding: 120px 0 140px;
  transition: 0.3s ease;

  @media all and (max-width: $desktop-width) {
    padding: 120px * 0.7 0 140px * 0.8;
  }

  @media all and (max-width: $tablet-width) {
    padding: 120px * 0.8 0 140px * 0.7;
  }

  @media all and (max-width: $medium-mobile-width) {
    padding: 120px * 0.7 0 140px * 0.6;
  }

  .container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .left {
      width: calc(53% - 45px);
      position: relative;
      margin: 0 45px 0 0;

      @media all and (max-width: $mobile-width) {
        width: 100%;
        max-width: 709px;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &:first-child {
          position: static;
          width: auto;
          height: auto;
        }
      }
    }

    .right {
      width: 47%;
      padding: 45px 0 0 0;

      @media all and (max-width: $mobile-width) {
        width: 100%;
      }

      img {
        margin: 0 0 35px;
      }

      h2 {
        max-width: 650px;

        span {
          box-shadow: inset 0 -0.14em $body-background-color,
            inset 0 -0.18em $heading-color;
        }
      }

      p {
        max-width: 540px;
      }
    }
  }
}

.testimonial-container {
  background-size: cover !important;
  padding: 130px 0;
  text-align: center;
  transition: 0.3s ease;

  @media all and (max-width: $desktop-width) {
    padding: 130px * 0.8 0;
  }

  @media all and (max-width: $tablet-width) {
    padding: 130px * 0.7 0;
  }

  @media all and (max-width: $medium-mobile-width) {
    padding: 130px * 0.6 0;
  }

  .forte {
    width: 260px;
    height: 95px;
    margin: 0 45px;
    transition: 0.3s ease;

    @media all and (max-width: $desktop-width) {
      width: 260px * 0.8;
      height: 95px * 0.8;
      margin: 0 45px * 0.8;
    }

    @media all and (max-width: $tablet-width) {
      width: 260px * 0.7;
      height: 95px * 0.7;
      margin: 0 45px * 0.7;
    }

    @media all and (max-width: $medium-mobile-width) {
      width: 260px * 0.6;
      height: 95px * 0.6;
      margin: 0 45px * 0.6;
    }
  }

  .hachem {
    width: 170px;
    height: 154px;
    margin: 0 45px;
    transition: 0.3s ease;

    @media all and (max-width: $desktop-width) {
      width: 170px * 0.8;
      height: 154px * 0.8;
      margin: 0 45px * 0.8;
    }

    @media all and (max-width: $tablet-width) {
      width: 170px * 0.7;
      height: 154px * 0.7;
      margin: 0 45px * 0.7;
    }

    @media all and (max-width: $medium-mobile-width) {
      width: 170px * 0.6;
      height: 154px * 0.6;
      margin: 0 45px * 0.6;
    }
  }

  .testimonial {
    padding: 60px 0 0 0;

    h5 {
      max-width: 1030px;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      font-size: 1.7em;
      font-style: italic;
      color: white;
    }
  }
}

.image-container {
  position: relative;
  text-align: center;

  img {
    display: block;
    width: 100%;
  }

  &.rooftop {
    @media all and (max-width: $small-mobile-width) {
      background: url(/assets/img/rooftop.jpg) center center no-repeat;
      background-size: cover;
      img {
        display: none;
      }

      .container {
        position: static;
        top: 0;
        left: 0;
        transform: translateX(0);
        text-align: center;
        padding: 50px 20px;
      }
    }
  }

  .container {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);

    @media all and (max-width: $desktop-width) {
      top: 120px * 0.8;
    }

    @media all and (max-width: $tablet-width) {
      top: 120px * 0.7;
    }

    @media all and (max-width: $medium-mobile-width) {
      top: 120px * 0.6;
    }

    h5 {
      letter-spacing: 1px;
      margin: 0 0 40px;

      @media all and (max-width: $tablet-width) {
        font-size: 4rem * 0.6;
        margin: 0 0 40px * 0.7;
      }

      @media all and (max-width: $medium-mobile-width) {
        font-size: 4rem * 0.5;
        margin: 0 0 40px * 0.6;
        br {
          display: none;
        }
      }
    }
  }

  &.bottom {
    .container {
      top: 60%;
    }
  }
}

.text-container {
  padding: 150px 0;
  @media all and (min-width: 1920px) {
    background-position: center right !important;
  }

  @media all and (max-width: $desktop-width) {
    padding: 150px * 0.8 0;
  }

  @media all and (max-width: $tablet-width) {
    padding: 150px * 0.7 0;
  }

  @media all and (max-width: $medium-mobile-width) {
    padding: 150px * 0.6 0;
  }

  .container {
    p {
      color: #b6b2a8;
      max-width: 800px;
      text-align: center;
      margin: 0;
    }
  }
}

.gallery-container {
  @media all and (max-width: 1380px) {
    margin: 120px 0 0 0;
  }

  @media all and (max-width: $desktop-width) {
    margin: 90px 0 0 0;
  }

  @media all and (max-width: $laptop-width) {
    margin: 60px 0 0 0;
  }

  .gallery {
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }
    img {
      display: block;
    }
  }
}

.lazy {
  position: relative;
  img {
    width: 100%;
    display: block;
  }
  .hide {
    transition: 0.5s ease;
    opacity: 0;
  }
  .highres {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;

    &.ready {
      opacity: 1;
    }
  }
}

.two-column {
  overflow: hidden;
  img {
    display: block;
  }

  p {
    line-height: 1.2em;
  }

  .container {
    width: $container-width + 40 + 110;
    display: flex;
    flex-wrap: wrap;

    .left {
      width: 41%;

      @media all and (max-width: $mobile-width) {
        width: 100%;
        text-align: left !important;
      }
    }

    .right {
      width: 59%;

      @media all and (max-width: $mobile-width) {
        width: 100%;
      }
    }
  }

  &.forte,
  &.hachem {
    @media all and (max-width: $laptop-width) {
      .container {
        padding: 0;
        flex-wrap: nowrap;

        .left {
          width: 150px;
        }

        .right {
          width: auto;

          padding-top: 30px;
          padding-bottom: 30px;
          padding-right: 20px;
        }
      }
    }
  }

  &.forte {
    @media all and (max-width: $laptop-width) {
      background: #d6d3cd;
    }
  }
}

.our-vision {
  padding: 70px 0;

  @media all and (max-width: $desktop-width) {
    padding: 70px * 0.8 0;
  }

  @media all and (max-width: $laptop-width) {
    padding: 70px * 0.7 0;
  }

  @media all and (max-width: $medium-mobile-width) {
    padding: 70px * 0.6 0;
  }

  .left {
    text-align: right;
    padding: 0 65px 0 0;
  }
}

.master-plan-hero {
  padding: 190px 0 70px;

  @media all and (max-width: $desktop-width) {
    padding: 190px * 0.8 0 70px * 0.8;
  }

  @media all and (max-width: $laptop-width) {
    padding: 100px 0 70px * 0.7;
  }

  @media all and (max-width: $medium-mobile-width) {
    padding: 100px 0 70px * 0.6;
  }

  .left {
    text-align: right;
    padding: 0 65px 0 0;
  }

  .right {
    p {
      &:first-child {
        margin-top: 8px;
      }
    }
  }
}

.darker {
  background: $body-background-color-darker;
}

.forte {
  .left {
    img {
      margin-left: auto;
    }
  }

  .right {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 300%;
      height: 100%;
      display: block;
      background: $body-background-color-darkest;
    }

    .content {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 30px;
      }
      .forte {
        width: 253px;
        height: 92px;
        margin: 0 0 30px;

        @media all and (max-width: $desktop-width) {
          width: 253px * 0.8;
          height: 92px * 0.8;
        }

        @media all and (max-width: $tablet-width) {
          width: 253px * 0.7;
          height: 92px * 0.7;
        }

        @media all and (max-width: $medium-mobile-width) {
          width: 253px * 0.6;
          height: 92px * 0.6;
        }
      }
    }
  }
}

.hachem {
  .left {
    img {
      margin-left: auto;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 30px;
    .hachem {
      width: 140px;
      height: 126px;
      margin: 0 0 30px;

      @media all and (max-width: $desktop-width) {
        width: 140px * 0.8;
        height: 126px * 0.8;
      }

      @media all and (max-width: $tablet-width) {
        width: 140px * 0.7;
        height: 126px * 0.7;
      }

      @media all and (max-width: $medium-mobile-width) {
        width: 140px * 0.6;
        height: 126px * 0.6;
      }
    }
  }
}

.aerial {
  transition: all 0.3s ease;
  margin-top: -20px;

  @media all and (max-width: $laptop-width) {
    margin-top: 20px;
  }
}

.stages {
  padding: 65px 0;

  @media all and (max-width: $desktop-width) {
    padding: 65px * 0.8 0;
  }

  @media all and (max-width: $tablet-width) {
    padding: 65px * 0.7 0;
  }

  @media all and (max-width: $medium-mobile-width) {
    padding: 65px * 0.6 0;
  }
  .container {
    text-align: center;
  }
  .stages-container {
    display: inline-block;
    margin: auto;
    width: auto;
    padding: 0 10px;
    border-radius: 20px;
    background: #eadcd6;

    .stage {
      display: inline-block;
      padding: 15px 20px;
      cursor: pointer;

      h4 {
        font-size: 2.2rem;
        margin: 0 0 3px;

        @media all and (max-width: $desktop-width) {
          font-size: 2.2rem * 0.8;
        }

        @media all and (max-width: $tablet-width) {
          font-size: 2.2rem * 0.7;
        }

        @media all and (max-width: $medium-mobile-width) {
          font-size: 2.2rem * 0.6;
        }
      }

      p {
        margin: 0;
      }
    }
  }
}

#master-plan {
  .mp-legends,
  .mp-stages {
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease;
  }

  .mp-legends {
    z-index: 3;
  }

  .show {
    opacity: 1;
  }
}

#stage-one,
#stage-two {
  padding: 45px 0;
  text-align: center;
}

#mp-dots {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s ease;

  &.show {
    opacity: 1;
  }

  .mp-dot {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    cursor: pointer;
    transition: 0.1s ease;

    &.hide {
      opacity: 0;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 16px;
      height: 16px;
      margin: -8px 0 0 -8px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 1);
      @include animation("pulse 2s infinite");

      @media all and (max-width: $mobile-width) {
        @include animation("pulse-mobile 2s infinite");
        width: 10px;
        height: 10px;
        margin: -5px 0 0 -5px;
      }
    }
  }
}

.floor-plans-plates {
  display: flex;
  max-width: 1000px;
  margin: 40px auto 125px;
  flex-wrap: wrap;

  @media all and (max-width: $desktop-width) {
    margin: 40px * 0.8 auto 125px * 0.8;
  }

  @media all and (max-width: $tablet-width) {
    margin: 40px * 0.7 auto 125px * 0.7;
  }

  @media all and (max-width: $medium-mobile-width) {
    margin: 40px * 0.6 auto 125px * 0.6;
  }
}

#floor-plans,
#floor-plates {
  width: 50%;
  padding: 40px 0;

  @media all and (max-width: $mobile-width) {
    width: 100%;
  }

  h4 {
    font-size: 2.5rem;

    @media all and (max-width: $desktop-width) {
      font-size: 2.5rem * 0.8;
    }

    @media all and (max-width: $tablet-width) {
      font-size: 2.5rem * 0.7;
    }
  }

  .floor-plan-container,
  .floor-plate-container {
    width: 143px * 2;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: auto;

    .floor-plan,
    .floor-plate {
      width: 50%;
      position: relative;
      cursor: pointer;
      img {
        display: block;

        &.hover {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: 0.3s ease;

          &:hover,
          &:focus,
          &:active {
            opacity: 1;
          }
        }
      }
    }
  }

  .floor-plate-container {
    .floor-plate {
      width: 100%;
    }
  }
}
