@font-face {
  font-family: "Aestetico";
  src: url("../fonts/aestetico/AesteticoFormal-Regular.woff2") format("woff2"),
    url("../fonts/aestetico/AesteticoFormal-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aestetico";
  src: url("../fonts/aestetico/AesteticoFormal-SemiBoldIt.woff2")
      format("woff2"),
    url("../fonts/aestetico/AesteticoFormal-SemiBoldIt.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aestetico";
  src: url("../fonts/aestetico/AesteticoFormal-SemiBold.woff2") format("woff2"),
    url("../fonts/aestetico/AesteticoFormal-SemiBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aestetico";
  src: url("../fonts/aestetico/AesteticoFormal-Light.woff2") format("woff2"),
    url("../fonts/aestetico/AesteticoFormal-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aestetico";
  src: url("../fonts/aestetico/AesteticoFormal-Light.woff2") format("woff2"),
    url("../fonts/aestetico/AesteticoFormal-Light.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

// @font-face {
//   font-family: "Aestetico";
//   src: url("../fonts/aestetico/AesteticoFormal-Bold.woff2") format("woff2"),
//     url("../fonts/aestetico/AesteticoFormal-Bold.woff") format("woff");
//   font-weight: bolder;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Aestetico Formal";
//   src: url("../fonts/aestetico/AesteticoFormal-Light.woff2") format("woff2"),
//     url("../fonts/aestetico/AesteticoFormal-Light.woff") format("woff");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "Aestetico Informal";
  src: url("../fonts/aestetico/AesteticoInformal-Regular.woff2") format("woff2"),
    url("../fonts/aestetico/AesteticoInformal-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aestetico Informal";
  src: url("../fonts/aestetico/AesteticoInformal-SemiBold.woff2")
      format("woff2"),
    url("../fonts/aestetico/AesteticoInformal-SemiBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
