/* Privacy */
$color-privacy: $main-color;
.privacy-content {
  position: relative;
  z-index: 1046;
  max-width: 960px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 30px 80px;
  background: white;
  font-family: Arial, sans-serif;
  @media all and (max-width: 600px) {
    padding: 30px;
  }

  h1 {
    display: block;
    margin-top: 20px;
    margin-bottom: 25px;
    font-weight: normal;
    font-size: 22.5pt;
    color: $color-privacy;
    border-bottom: 1px dotted #666;
    padding-bottom: 25px;
  }

  h2 {
    font-weight: normal;
    font-size: 22.5pt;
    color: $color-privacy;
    margin: 1em 0 0.6em;

    // &:first-of-type {
    //   margin-top: 0;
    // }
  }

  h3 {
    color: $color-privacy;
    letter-spacing: normal;
    -webkit-text-fill-color: initial;
    font-size: 10pt;
    font-weight: normal;
    padding-bottom: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: normal;
  }

  p,
  li {
    margin: 0;
    margin-bottom: 10px;
    font-size: 10pt;
    color: #666;
    font-weight: normal;
    font-family: Arial, sans-serif;
  }

  // ul {
  //   margin-left: -20px;
  //   li {
  //     margin-bottom: 8px;
  //     padding-left: 19px;
  //     &:before {
  //       content: "•";
  //       font-size: 1.4em;
  //       color: #888;
  //     }
  //   }

  //   @media all and (max-width: 600px) {
  //     margin-left: -10px;

  //     li {
  //       padding-left: 9px;
  //     }
  //   }
  // }

  a {
    color: $color-privacy;
    text-decoration: underline;
    &:hover {
      color: #666;
    }
  }

  li {
    margin-bottom: 5px;
  }

  ul,
  ol {
    margin-bottom: 8px;
  }
}
